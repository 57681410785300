<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="isHomeActive" class="pa-0">
      <v-sheet
        ref="sheet"
        color="primary"
        class="pa-2"
        :class="{
          'v-sheet__desktop': $voicer.isDesktopOrTablet,
          'ma-3 mb-4': $voicer.isDesktopOrTablet,
        }"
      >
        <div
          v-if="isAuthorized"
          class="text-alternate"
          :class="{
            'body-2 font-weight-normal': $voicer.isDesktopOrTablet,
            'body-2': $voicer.isMobile,
          }"
        >
          <div class="pl-2 pr-2 pt-1 white--text">
            Bienvenue sur Radio POINT.P !

            <br />
            Votre media 100% dédié aux collaborateurs POINT.P. Envoyez une
            dédicace au
            <a href="tel:0186868787">01 86 86 87 87</a> ou sur
            <a href="mailto:contact@radiopointp.fr">contact@radiopointp.fr</a>
          </div>
          <v-btn
            ref="btn"
            color="#FF803E"
            rounded
            depressed
            class="white--text"
            @click.stop="handleOpen()"
          >
            <v-icon color="#ffffff" class="mr-2"> mdi-volume-high </v-icon>
            Écoutez la radio en direct
          </v-btn>
        </div>
        <div
          v-if="!isAuthorized"
          class="text-alternate white--text"
          :class="{
            'body-2 font-weight-normal': $voicer.isDesktopOrTablet,
            'body-2': $voicer.isMobile,
          }"
        >
          Vous n'êtes pas autorisé à accéder à Radio POINT.P
        </div>
      </v-sheet>
    </v-container>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      showFeedbackModal: false,
    }
  },
  computed: {
    isHomeActive() {
      return this.$route.name === 'index'
    },
    isAuthorized() {
      return (
        this.$spoke.user.data.protected.segments &&
        this.$spoke.user.data.protected.segments.some((segment) => {
          return segment.name === 'authorized'
        })
      )
    },
  },
  methods: {
    handleOpen(e) {
      this.$spoke
        .collection('contents')
        .getOne('66f28234b44241fe5425108e')
        .on('success', (content) => {
          this.$store.dispatch('player/changePlayerContent', {
            content,
            noRedirect: false,
          })
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.w-block__pre-heading {
  justify-content: center;
  text-align: center;
  font-weight: bold;

  .v-sheet {
    text-align: center;
    border-radius: 8px;
  }
  .v-sheet__desktop {
    & > div {
      align-items: center;

      .v-btn {
        border-radius: 4px;
      }
    }
  }
}
.body-2 {
  text-align: center;
}
.white--text {
  a {
    color: white;
    text-decoration: underline;
  }
}

.text-alternate {
  font-size: 18px !important;
}
</style>
